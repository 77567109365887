/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Children } from 'react'

import { media } from '../../constants'
import { Row, Cell } from '../Grid'
import Text, { TEXT_SIZE_SMALL } from './Text'

export const LAYOUT_V_CADENCE_SIZE_EXTRA_SMALL = 'extra-xsmall'
export const LAYOUT_V_CADENCE_SIZE_SMALL = 'small'
export const LAYOUT_V_CADENCE_SIZE_MEDIUM = 'medium'
export const LAYOUT_V_CADENCE_SIZE_LARGE = 'large'
export const LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE = 'extra-large'

//////////////////////////

export const LayoutIndented = ({ children }) => {
	return (
		<div data-comp="layout-indented">
			<Row>
				<Cell
					cols={{
						[media.small]: 12,
						[media.mediumUp]: 10,
					}}
					offset={{
						[media.small]: 0,
						[media.mediumUp]: 1,
					}}
				>
					{children}
				</Cell>
			</Row>
		</div>
	)
}

//////////////////////////

export const LayoutCol2 = ({ children, strict, verticalCadenceSize }) => {
	if (strict) {
		// Maintain 2 columns all the time
		return (
			<div
				data-comp="layout-col-2-strict"
				style={buildLayoutVCadenceStyle({
					size: verticalCadenceSize,
					cols: 2,
				})}
			>
				<Row>
					{Children.map(children, (child, index) => (
						<Cell
							key={index}
							cols={{
								[media.small]: 6,
								[media.mediumUp]: 5,
							}}
							offset={{
								[media.small]: 0,
								[media.mediumUp]: index % 2 === 0 ? 1 : 0,
							}}
						>
							{child}
						</Cell>
					))}
				</Row>
			</div>
		)
	}

	// Flip to 1 column on small/medium
	return (
		<div data-comp="layout-col-2">
			<Row
				style={buildLayoutVCadenceStyle({
					size: verticalCadenceSize,
					cols: {
						[media.large]: 2,
					},
				})}
			>
				{Children.map(children, (child, index) => (
					<Cell
						key={index}
						cols={{
							[media.small]: 12,
							[media.medium]: 10,
							[media.large]: 5,
						}}
						offset={{
							[media.small]: 0,
							[media.medium]: 1,
							[media.large]: index % 2 === 0 ? 1 : 0,
						}}
					>
						{child}
					</Cell>
				))}
			</Row>
		</div>
	)
}

LayoutCol2.propTypes = {
	children: PropTypes.node,
	// Strict maintains 2 columns all the time
	strict: PropTypes.bool,
	verticalCadenceSize: PropTypes.oneOf([
		LAYOUT_V_CADENCE_SIZE_SMALL,
		LAYOUT_V_CADENCE_SIZE_MEDIUM,
		LAYOUT_V_CADENCE_SIZE_LARGE,
	]),
}

LayoutCol2.defaultProps = {
	children: [],
	verticalCadenceSize: LAYOUT_V_CADENCE_SIZE_SMALL,
	strict: false,
}

//////////////////////////

export const LayoutSplitHeading = ({ title, children }) => {
	return (
		<div data-comp="layout-split-heading">
			<Row>
				<Cell
					cols={{
						[media.small]: 12,
						[media.mediumUp]: 3,
					}}
					offset={{
						[media.small]: 0,
						[media.mediumUp]: 1,
					}}
				>
					<Text>
						<h3
							css={{
								[media.small]: {
									marginBottom: '1.25rem',
								},
							}}
						>
							{title}
						</h3>
					</Text>
				</Cell>
				<Cell
					cols={{
						[media.small]: 12,
						[media.mediumUp]: 7,
					}}
				>
					{children}
				</Cell>
			</Row>
		</div>
	)
}

//////////////////////////

export const vCadenceSpaceMap = {
	[LAYOUT_V_CADENCE_SIZE_EXTRA_SMALL]: {
		[media.small]: { marginTop: '2rem' }, // => 32
		[media.medium]: { marginTop: '2rem' }, // => 32
		[media.large]: { marginTop: '2.5rem' }, // => 40
	},
	[LAYOUT_V_CADENCE_SIZE_SMALL]: {
		[media.small]: { marginTop: '3.5rem' }, // => 56
		[media.medium]: { marginTop: '3.5rem' }, // => 56
		[media.large]: { marginTop: '3.5rem' }, // => 56
	},
	[LAYOUT_V_CADENCE_SIZE_MEDIUM]: {
		[media.small]: { marginTop: '3.5rem' }, // => 56
		[media.medium]: { marginTop: '5rem' }, // => 80
		[media.large]: { marginTop: '5rem' }, // => 80
	},
	[LAYOUT_V_CADENCE_SIZE_LARGE]: {
		[media.small]: { marginTop: '6rem' }, // => 96
		[media.medium]: { marginTop: '8rem' }, // => 128
		[media.large]: { marginTop: '10rem' }, // => 160
	},
	[LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE]: {
		[media.small]: { marginTop: '8rem' }, // => 128
		[media.medium]: { marginTop: '10rem' }, // => 160
		[media.large]: { marginTop: '16rem' }, // => 256
	},
}

export const buildLayoutVCadenceStyle = ({ size, cols }) => {
	const baseStyle = {
		'& > *:nth-child(n + 2)': {
			// Set the v cadence as margin-top for all elements
			// except the first one
			...vCadenceSpaceMap[size],
		},
	}

	// Now also override the first element in each
	// visual "columns" to also have no margin top.
	// This can be set as a hard value, or split out by
	// media query
	const colsStyle = !cols
		? null
		: typeof cols === 'number'
		? {
				[`& > *:nth-child(-n + ${cols})`]: {
					marginTop: 0,
				},
		  }
		: Object.keys(cols).reduce((map, query) => {
				return {
					...map,
					[query]: {
						[`& > *:nth-child(-n + ${cols[query]})`]: {
							marginTop: 0,
						},
					},
				}
		  }, {})

	return {
		...baseStyle,
		...colsStyle,
	}
}

export const LayoutVerticalCadence = ({ children, size, cols }) => {
	return (
		<div
			data-comp="vertical-cadence"
			css={buildLayoutVCadenceStyle({ size, cols })}
		>
			{children}
		</div>
	)
}

LayoutVerticalCadence.propTypes = {
	children: PropTypes.node,
	size: PropTypes.oneOf([
		LAYOUT_V_CADENCE_SIZE_EXTRA_SMALL,
		LAYOUT_V_CADENCE_SIZE_SMALL,
		LAYOUT_V_CADENCE_SIZE_MEDIUM,
		LAYOUT_V_CADENCE_SIZE_LARGE,
		LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE,
	]),
	cols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
}

LayoutVerticalCadence.defaultProps = {
	children: [],
	size: LAYOUT_V_CADENCE_SIZE_SMALL,
	cols: 1,
}

/////////////////////

export const LayoutTextSplit = ({ children, defaultTitle }) => {
	const groupChildren = Children.toArray(children).reduce((data, child) => {
		const { type } = child

		if (
			type === 'h1' ||
			type === 'h2' ||
			type === 'h3' ||
			type === 'h4' ||
			type === 'h5'
		) {
			const titleString = Children.toArray(child.props.children).reduce(
				(titleString, headingChild) => {
					if (typeof headingChild === 'string') {
						return headingChild
					}
					return titleString
				},
				''
			)

			return [
				...data,
				{
					type,
					title: titleString,
					content: [],
				},
			]
		}

		let newData = [...data]

		// If the first child is NOT a header, create one with the default title
		if (newData.length === 0) {
			newData = [
				...data,
				{
					type: 'h3',
					title: defaultTitle,
					content: [],
				},
			]
		}

		const index = newData.length - 1
		const content = newData[index].content
		newData[index].content = [...content, child]
		return newData
	}, [])
	return groupChildren.map(({ title, content }, idx) => {
		return (
			<LayoutSplitHeading title={title} key={idx}>
				<Text defaultParagraph={TEXT_SIZE_SMALL}>{content}</Text>
			</LayoutSplitHeading>
		)
	})
}

/////////////////////

export {}
