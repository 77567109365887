/** @jsx jsx */
import { Fragment, FC, useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import axios from 'axios'
import qs from 'query-string'
import { PageRendererProps, navigate } from 'gatsby'

import Helmet from '../../../components/HelmetHelper'
import Container from '../../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../../components/content/Text'
import { media, THEME_CITRON } from '../../../constants'
import Slide from '../../../components/Slide'
import {
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_SMALL,
} from '../../../components/content/Layout'
import { provideAccentTheme } from '../../../context/AccentThemeContext'

import { InlineLink } from '../../../components/content/Link'

type Props = PageRendererProps & {
	accentTheme: string
}

type RegistrationData = {
	email: string
	key: string
	media: string
}

const EthicsWatchPage: FC<Props> = ({ location }) => {
	let key: string | null = ''
	try {
		if (location) {
			key = qs.parseUrl(location.href).query.k as string
		}
	} catch (error) {}

	const [_, setFetching] = useState(true)
	const [
		registrationData,
		setRegistrationData,
	] = useState<RegistrationData | null>()

	useEffect(() => {
		if (!key) {
			navigate('/academy/design-ethics')
			return
		}
		axios
			.get(
				`/.netlify/functions/media-registration/design-ethics-masterclass/${key}`
			)
			.then(res => {
				setRegistrationData(res.data)
				setFetching(false)
			})
			.catch(error => {
				console.log(error)
				navigate('/academy/design-ethics')
				setFetching(false)
			})
	}, [])

	return (
		<Fragment>
			<Helmet>
				<meta name="robots" content="noindex" />
				<link rel="canonical" href="/academy/design-ethics" />

				<title>Vincent Hofmann on Design Ethics</title>

				<meta content="Vincent Hofmann on Design Ethics" property="og:title" />
				<meta content="Vincent Hofmann on Design Ethics" name="twitter:title" />
				<meta
					name="description"
					content="A masterclass on the ethics and responsibility of design."
				/>
				<meta
					property="og:description"
					content="A masterclass on the ethics and responsibility of design."
				/>
				<meta
					content="https://freeassociation.com/share/ethics-share.jpg"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/ethics-share.jpg"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide>
				<div
					css={{
						width: '100vw',
						minHeight: 'calc(100vh - 10rem)',
						padding: '3rem 0',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						position: 'relative',
					}}
				>
					<Container>
						<div>
							<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_SMALL}>
								<div
									css={{
										[media.small]: {
											['* + *']: {
												marginTop: '1rem',
											},
										},
										[media.mediumUp]: {
											justifyContent: 'space-between',
											display: registrationData ? 'flex' : 'none',
										},
									}}
								>
									<Text>
										<h1 className="--eyebrow">
											Vincent Hofmann on Design Ethics
										</h1>
										{registrationData && (
											<p className="--small">
												<strong>Watching as</strong> &mdash;{' '}
												{registrationData.email}
											</p>
										)}
									</Text>
									<div>
										<Text defaultParagraph={TEXT_SIZE_SMALL}>
											<InlineLink to="/academy/design-ethics">
												Back to description
											</InlineLink>
										</Text>
									</div>
								</div>
								<div>
									<div
										css={{
											position: 'relative',
											width: '100%',
											paddingBottom: '56.25%', // 16:9
											height: 0,
											background: '#fafafa',
										}}
									>
										<iframe
											css={{
												position: 'absolute',
												width: '100%',
												height: '100%',
												top: 0,
												left: 0,
											}}
											// TODO secure the youtube link somehow
											src="https://www.youtube.com/embed/Q01BPwGxvn0"
											title="FA Academy - Design Ethics Materclass"
											frameBorder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
										></iframe>
									</div>
								</div>
							</LayoutVerticalCadence>
						</div>
					</Container>
				</div>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(EthicsWatchPage, THEME_CITRON)
